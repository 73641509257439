<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :append-to-body="true"
    width="90%">
    <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px">
      <div class="wxts_msg_search" >
        <span>模型基本信息</span>
      </div>
      <el-alert
        title="提示：1、选取文件；2、上传文件解析字段，3、根据解析结果设置其他信息。"
        type="info"
        v-if="!modifyState"
        :closable="false">
      </el-alert>
      <el-alert
        title="注意：默认使用表格第一行数据为表头，提交数据时将使用“目标表”及“模型字段”信息创建库表并插入数据"
        type="info"
        v-if="!modifyState"
        :closable="false">
      </el-alert>
      <el-form-item label="数据源文件" prop="files" v-if="!modifyState">
        <div class="panel-button">
          <el-upload
            class="upload-demo"
            ref="uploadFile"
            action
            :accept="accept"
            :http-request="uploadRequest"
            :with-credentials="true"
            :before-upload="beforeAvatarUpload"
            :on-success="handlSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="fileLimit"
            :on-exceed="handleExceed">
            <el-button type="primary" :loading="dataListLoading" slot="trigger">选取文件</el-button>
            <el-button type="success" :loading="dataListLoading" @click="submitUpload">上传文件解析字段</el-button>
          </el-upload>
        </div>
      </el-form-item>
      <el-row :gutter="20" v-if="!modifyState">
        <el-col :span="12">
          <el-form-item label="源数据" prop="categoryId">
            <el-select v-model="sheetName" placeholder="请选择源数据" @change="changeSheet">
              <el-option
                v-for="item in analysisFileList"
                :key="item.sheetName"
                :label="dataForm.dmType == 'csv' ? item.fileName : (item.fileName + '-' + item.sheetName)"
                :value="item.sheetName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目标表" prop="tableName">
            <el-input v-model="dataForm.tableName" placeholder="目标表"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="模型分组" prop="categoryId">
            <el-cascader
              v-model="dataForm.categoryId"
              :options="categoryOptions"
              :show-all-levels="true"
              placeholder="请选择模型分组"
              style="width:100%"
              :props="{ checkStrictly: true, value: 'id', label: 'name' }"
              filterable
              disabled
              :filter-method="filterNode">
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="modifyState">
          <el-form-item label="目标表" prop="tableName">
            <el-input v-model="dataForm.tableName" placeholder="目标表" disabled></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="数据源" prop="dsId">
            <el-select v-model="dataForm.dsId" filterable placeholder="请选择数据源" style="width:100%" disabled>
              <el-tooltip v-for="item in dsOptions" :key="item.id" placement="top-start" :open-delay="300" :enterable="false">
                <div slot="content">
                  类型：{{ item.dsType }}<br/>
                  名称：{{ item.dsDatabase }}<br/>
                  地址：{{ item.dsIp }}:{{ item.dsPort }}<br/>
                  用户：{{ item.dsUser }}<br/>
                </div>
                <el-option :label="item.name" :value="item.id"></el-option>
              </el-tooltip>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="模型名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="模型名称" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="模型类型" prop="dmType">
            <sysDictSelect v-model="dataForm.dmType" dictType="data_model_type" placeholder="请选择模型类型" disabled/>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-form-item label="表/视图名称" prop="tableName" v-if="dataForm.dmType == 'table' || dataForm.dmType == 'view'">
        <el-input v-model="dataForm.tableName" placeholder="表/视图名称" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea"
          :autosize="{ minRows: 3, maxRows: 6}"
          v-model="dataForm.remark"
          placeholder="备注"
          maxlength="1000"
          show-word-limit>
        </el-input>
      </el-form-item>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="字段管理" name="columnManage">
          <div class="wxts_msg_search" >
            <span>模型字段信息</span>
            <div class="f_t">
              <el-button-group>
                <el-button type="primary" @click="addVirtualColumn()">新增虚拟字段</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="dataForm.columns" border stripe style="width: 100%;" v-loading="dataListLoading" :max-height="450">
            <el-table-column prop="columnName" header-align="center" align="center" label="建表字段名称" width="180">
              <template slot-scope="scope">
                <el-input v-model="scope.row.columnName" placeholder="字段名称" maxlength="100" show-word-limit></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="customColumnName" header-align="center" align="center" label="原始字段名称" width="180"></el-table-column>
            <el-table-column prop="customColumnType" header-align="center" align="center" label="字段类型" width="130">
              <template slot-scope="scope">
                <sysDictSelect v-model="scope.row.customColumnType" dictType="column_type" placeholder="请选择字段类型" filterable/>
              </template>
            </el-table-column>
            <el-table-column prop="columnType" header-align="center" align="center" label="原始类型" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.virtualColumn == 1">/</span>
                <span>{{scope.row.columnType}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="columnLength" header-align="center" align="center" label="字段长度" width="155">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.columnLength"
                  placeholder="字段长度"
                  :step="1"
                  step-strictly
                  controls-position="right">
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column prop="columnRemark" header-align="center" align="center" label="中文备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.columnRemark" placeholder="字段名称" maxlength="500" show-word-limit></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="virtualColumn" header-align="center" align="center" label="是否虚拟字段" width="100">
              <template slot-scope="scope">
                <sysDict type="yes_or_no" :value="scope.row.virtualColumn"/>
              </template>
            </el-table-column>
            <el-table-column prop="virtualType" header-align="center" align="center" label="虚拟字段填充模式" width="120">
              <template slot-scope="scope">
                <sysDictSelect :clearable="false" v-if="scope.row.virtualColumn == 1" v-model="scope.row.virtualType" dictType="virtual_type" placeholder="虚拟字段填充模式" filterable/>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column prop="virtualContent" header-align="center" align="center" label="虚拟字段填充内容">
              <template slot-scope="scope">
                <el-input type="textarea"
                  v-if="scope.row.virtualColumn == 1"
                  :autosize="{ minRows: 1, maxRows: 6}"
                  v-model="scope.row.virtualContent"
                  placeholder="虚拟字段填充内容"
                  maxlength="500"
                  show-word-limit>
                </el-input>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" width="60" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteHandle(scope)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="数据预览" name="datePreview">
          <div class="wxts_msg_search" >
            <span>预览数据默认显示10条</span>
            <div class="f_t" v-if="modifyState">
              <el-button-group>
                <el-button type="success" @click="getPreviewList()">刷新</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="previewList" border stripe v-loading="previewListLoading" style="width: 100%;" :max-height="450">
            <template v-for="(item, index) in dataForm.columns">
              <el-table-column
                :key="index"
                :prop="item.customColumnName"
                header-align="center"
                align="center"
                show-overflow-tooltip
                :label="item.columnRemark ? item.columnRemark : item.customColumnName">
                <template slot-scope="scope">
                  {{showColInfo(item.customColumnName, item.columnName, scope.row)}}
                </template>
                </el-table-column>
            </template>
          </el-table>
          <!-- <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper">
          </el-pagination> -->
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllById, analysisFileAndSaveOrUpdate, analysisByFile, getPreviewList } from '@/api/hbi/datamodel.js'
import { getAllList } from '@/api/hbi/datacategory.js'
import { getAllDsList } from '@/api/hbi/dynamicdatasource.js'
import { treeDataTranslate } from '@/utils/index.js'
export default {
  data () {
    const validateTableName = (rule, value, callback) => {
      if (!rule.required && !value) {
        callback()
      } else {
        if (!value || value.length == 0) {
          callback(new Error(`表/视图名称不能为空`))
        } else if (!/^([A-Za-z]+[a-zA-Z0-9_]*\.)?([A-Za-z]+[a-zA-Z0-9_]*)$/.test(value)) {
          callback(new Error(`表/视图名称格式不正确`))
        } else {
          callback()
        }
      }
    };
    return {
      activeName: 'columnManage', // 当前选择的tab
      visible: false, // 页面显示标记
      isOnSubmit: false, // 提交状态
      formLoading: false, // 表单加载状态
      dataListLoading: false, // 字段信息加载状态
      previewListLoading: false, // 预览数据加载状态
      dsOptions: [], // 数据源列表
      categoryOptions: [], // 模型分组树形列表
      previewList: [], // 预览列表
      dataForm: {
        id: null,
        categoryId: '',
        name: '',
        dmType: '',
        dsId: '',
        tableName: '',
        sqlText: '',
        version: '',
        deleteFlag: '',
        remark: '',
        columns:[],
        nameLine: 1,
        startLine: 2,
        endLine: ''
      },
      dataRule: {
        categoryId: [{ required: true, message: '请选择模型分组', trigger: ['blur','change'] }],
        dsId: [{ required: true, message: '请选择数据源', trigger: ['blur','change'] }],
        name: [{ required: true, message: '模型名称不能为空', trigger: 'blur' }],
        dmType: [{ required: true, message: '请选择模型类型 ', trigger: ['blur','change'] }],
        tableName: [{ required: true, validator: validateTableName, trigger: 'blur' }]
      },
      modifyState: false,//true修改，false新增
      fileList:[],// 上传文件信息
      maxFileSize: 10, // 文件大小限制
      fileLimit: 1, // 文件数量限制
      accept:'.xls,.xlsx',// 允许的文件类型，多个用逗号隔开
      analysisFileList:[],//解析excel不同sheet获取的数据
      sheetName:'',// sheet页
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
    }
  },
  created() {
    this.getCategoryOptions()
    //this.getDsOptions()
  },
  methods: {
    /** 修改模型 */
    init (id) {
      this.modifyState = true
      this.dataForm.id = id
      this.visible = true
      this.previewList = []
      this.fileList = []
      this.activeName = 'columnManage'
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.formLoading = true
          getAllById(id).then(({ data }) => {
            if (data && data.code === 0) {
              let res = data.data
              for (let item in this.dataForm) {
                if (res[item] || res[item] === 0) {
                  if(item == 'columns'){
                    this.dataForm[item] = res[item]
                  }else{
                    this.dataForm[item] = res[item] + ''
                  }
                } else {
                  if(item == 'columns'){
                    this.dataForm[item] = []
                  }else{
                    this.dataForm[item] = ''
                  }
                }
              }
              this.getPreviewList()
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.formLoading = false
          })
        }
      })
    },
    /** 新增模型 */
    initAdd(dsId, table, dmType, categoryId){
      // 初始化页面
      this.visible = true
      this.modifyState = false
      this.previewList = []
      this.fileList = []
      this.activeName = 'columnManage'
      for (let item in this.dataForm) {
        if(item == 'columns'){
          this.dataForm[item] = []
        } else if(item == 'nameLine'){
          this.dataForm[item] = 1
        } else if(item == 'startLine'){
          this.dataForm[item] = 2
        } else{
          this.dataForm[item] = ''
        }
      }
      if(dmType == 'csv'){
        this.accept = '.csv'
      }else{
        this.accept = '.xls,.xlsx'
      }
      this.dataForm.dmType = dmType
      this.dataForm.dsId = dsId
      this.dataForm.tableName = table
      this.dataForm.categoryId = categoryId
      this.$nextTick(()=>{
        this.$refs.dataForm.clearValidate()
      })
    },
    // 表单提交
    async dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if(!this.dataForm.id && (!this.fileList || this.fileList.length == 0)){
            this.$message.error('数据源文件不存在，请选择数据源文件')
            return
          }
          this.isOnSubmit = true
          if(this.modifyState){// 如果是修改则将表单信息添加到analysisFileList
            this.analysisFileList.push(JSON.parse(JSON.stringify(this.dataForm)))
          }
          const data_ = JSON.parse(JSON.stringify(this.analysisFileList))
          // 使用form表单的数据格式
          const paramsData = new FormData()
          // 将上传文件依次添加到参数paramsData中
          if(this.fileList && this.fileList.length > 0){
            paramsData.append(`file`, this.fileList[0])
            // for(let index in this.fileList){
            //   paramsData.append(`files`, this.fileList[index])
            // }
          }
          for(let item of data_){
            // 修改时，如果没有修改categoryId则该属性为字符串无需提取选择的id
            item.categoryId = typeof item.categoryId == 'string' ? item.categoryId : item.categoryId[item.categoryId.length-1]
            item.dataList = []
            // 将表单数据添加到参数paramsData中
            // for(let i in item){
            //   paramsData.append(i, item[i])
            // }
          }
          paramsData.append('models', JSON.stringify(data_))
          // 表单数据和文件一起上传,调用后端接口
          analysisFileAndSaveOrUpdate(paramsData, !this.modifyState).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message.success('操作成功')
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    },
    /** 新增虚拟字段信息 */
    addVirtualColumn(){
      this.dataForm.columns.push({
        columnName: '',
        customColumnName: '',
        customColumnType: '',
        columnType: '',
        columnLength: '',
        columnRemark: '',
        virtualColumn: 1,
        virtualType: '1',
        virtualContent: '',
      })
    },
    /** 删除字段信息 */
    deleteHandle(scope){
      this.$confirm(`确定对模型字段[${scope.row.columnName}]进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataForm.columns.splice(scope.$index, 1)
      }).catch(() => { })
    },
    /** 获取分组信息 */
    getCategoryOptions () {
      getAllList().then(({ data }) => {
        if (data && data.code === 0) {
          const list = data.data
          this.categoryOptions = list && list.length > 0 ? treeDataTranslate(list, 'id', 'parentId') : [];
        } else {
          this.categoryOptions = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {})
    },
    /** 获取数据源信息 */
    getDsOptions () {
      getAllDsList().then(({ data }) => {
        if (data && data.code === 0) {
          this.dsOptions = data.data
        } else {
          this.dsOptions = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {})
    },
    /** 级联选择器内容过滤，忽略大小写 */
    filterNode(node, keyword){
      if (!keyword) return true;
      let v = keyword.toLowerCase()
      let d = node.data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    },
    /** 修改时默认从数据库加载预览数据 */
    handleClick(tab) {},
    /** 表格预览数据展示，主要用于oracle数据库查询结果字段大写展示数据为空 */
    showColInfo(label, columnName, data){
      if(!data.hasOwnProperty.call(label)){
        label = label.toUpperCase()
      }
      let res = data[label]
      if(!res){
        if(!data.hasOwnProperty.call(columnName)){
          columnName = columnName.toUpperCase()
        }
        res = data[columnName]
      }
      return res
    },
    /** 获取预览数据 */
    getPreviewList(){
      this.previewListLoading = true
      const params_ = JSON.parse(JSON.stringify(this.dataForm))
      // 修改时，如果没有修改categoryId则该属性为字符串无需提取选择的id
      params_.categoryId = typeof params_.categoryId == 'string' ? params_.categoryId : params_.categoryId[params_.categoryId.length-1]
      this.$set(params_, 'page', this.pageIndex);
      this.$set(params_, 'limit', this.pageSize);
      getPreviewList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.previewList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.previewList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.previewListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getPreviewList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getPreviewList()
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    /** 上传前 */
    beforeAvatarUpload (file) {
      let pos = file.name.lastIndexOf('.')
      let lastName = file.name.substring(pos, file.name.length)
      //const xlsx = /^\.xlsx?$/.test(lastName.toLowerCase())
      // if (!xlsx) {
      //   this.$message.error(`上传文件只能是xls或xlsx格式，当前文件【${file.name}】格式为【${lastName.toLowerCase()}】`)
      // }
      let accepts = this.accept.toLowerCase().split(",")
      const xlsxIndex = accepts.indexOf(lastName.toLowerCase())
      if (xlsxIndex  < 0) {
        this.$message.error(`上传文件允许${accepts.join('、')}格式，当前文件【${file.name}】格式为【${lastName.toLowerCase()}】`)
      }
      const isLt5M = file.size / 1024 / 1024 < this.maxFileSize
      if (!isLt5M) {
        this.$message.error(`上传文件大小不能超过${this.maxFileSize}MB，当前文件【${file.name}】大小【${file.size / 1024 / 1024}MB】`)
      }
      return xlsxIndex && isLt5M
    },
    /** 移除文件前 */
    beforeRemove(file/* , fileList */) {
      return this.$confirm(`确定移除${ file.name }？`);
    },
    /** 移除文件 */
    handleRemove(/*file , fileList */){
      this.fileList = []
    },
    /** 超出数量限制 */
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择${this.fileLimit}个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件，如需重新选择请先移除当前文件。`);
    },
    /** 上传成功 */
    handlSuccess (res, file, fileList) {
      if (res.code === 0) {
        this.loading = false
        this.importMsg = res.msg
        this.dataList = this.dataList.concat(res.data)
      } else {
        this.loading = false
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },
    /** 重写默认上传方法 */
    uploadRequest(option){
      this.fileList.push(option.file)
    },
    /** 手动上传 */
    async submitUpload(){
      if(!this.fileList || this.fileList.length == 0){
        this.$message.error('数据源文件不存在，请选择数据源文件')
        return
      }
      // 使用form表单的数据格式
      const paramsData = new FormData()
      // 添加文件到参数中
      paramsData.append(`file`, this.fileList[0])
      // 将上传文件添加到参数paramsData中
      // for(let index in this.fileList){
      //   paramsData.append(`files`, this.fileList[index])
      // }
      // 将表单数据添加到参数paramsData中
      // paramsData.append('nameLine', this.dataForm.nameLine)
      // paramsData.append('startLine', this.dataForm.startLine)
      // paramsData.append('endLine', this.dataForm.endLine)
      // 表单数据和文件一起上传,调用后端接口
      this.dataListLoading = true
      analysisByFile(paramsData).then(({ data }) => {
        if (data && data.code === 0) {
          this.analysisFileList = data.data
          // 设置解析的第一个信息为编辑
          if(this.analysisFileList && this.analysisFileList.length > 0){
            this.sheetName = this.analysisFileList[0].sheetName
            this.changeSheet(this.analysisFileList[0].sheetName)
          }
          this.$message.success('操作成功')
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        this.$message.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    /**
     * 选择sheet
     */
    changeSheet(val){
      for(let item of this.analysisFileList){
        if(item.sheetName == val){
          if(!item.categoryId || item.categoryId == ''){
            this.$set(item, 'categoryId', this.dataForm.categoryId);
          }
          if(!item.dmType || item.dmType == ''){
            this.$set(item, 'dmType', this.dataForm.dmType);
          }
          if(!item.name || item.name == ''){
            this.$set(item, 'name', item.sheetName);
          }
          this.dataForm = item;
          this.previewList = item.dataList;
          break;
        }
      }
    }
  }
}
</script>
